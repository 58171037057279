'use client';

import { CTA, Layout } from '@/components/core';
import { ProductSuite, Footnotes, Predict, Disclaimer } from '@/components/shared';
import { Faqs } from '@/components/solutions/shared';
import {
  Header,
  TheWhy,
  TheProduct,
  ComprehensiveCare,
  HolisticHealth,
  HowItWorks,
  Testimonial,
} from '@/components/solutions/bloom';

export default function PageContent({
  bannerInfo,
  content,
  suite,
  categories,
  initialContentFaq,
  productSlug,
}) {
  const {
    header,
    theWhy,
    cta,
    faq,
    theProduct,
    comprehensiveCare,
    holisticHealth,
    howItWorks,
    testimonial,
    footnotes,
    predict,
    disclaimer,
  } = content;

  return (
    <Layout bannerInfo={bannerInfo}>
      {header && <Header content={header} />}
      {theWhy && <TheWhy content={theWhy} />}
      {theProduct && <TheProduct content={theProduct} />}
      {predict && <Predict content={predict} />}
      {comprehensiveCare && <ComprehensiveCare content={comprehensiveCare} />}
      {holisticHealth && <HolisticHealth content={holisticHealth} />}
      {howItWorks && <HowItWorks content={howItWorks} />}
      {testimonial && <Testimonial content={testimonial} />}
      {suite && <ProductSuite content={suite} />}
      {faq && !!initialContentFaq.length && (
        <Faqs
          header={faq}
          categories={categories}
          initialContentFaq={initialContentFaq}
          product={productSlug}
          noOverflow
        />
      )}
      {cta && <CTA {...cta} />}
      {disclaimer && <Disclaimer content={disclaimer} />}
      {footnotes && <Footnotes footNotes={footnotes} />}
    </Layout>
  );
}
